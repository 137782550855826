<template>
  <v-main style="height: 100%">
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 md8 style="margin-bottom: 80px; max-width: 850px">
          <div v-if="isSigningInProcess">
            ログインしています、少々お待ちください
          </div>
          <div v-else>
            <h2 class="mb-10 primary--text">ログインフォーム</h2>
            <v-card :style="loginStyles.bg" :class="'outset'">
              <v-row class="py-8">
                <v-col cols="12" md="6" class="text-center">
                  <div>
                    <img
                      src="@/assets/images/logo_lg.png"
                      style="max-width: 500px; width: 100%"
                      class="ml-5 mt-5"
                    />
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="py-3 pr-10">
                  <v-form @submit.prevent>
                    <v-card-text class="pt-0">
                      <v-text-field
                        v-model="email"
                        prepend-icon="mdi-account"
                        name="login"
                        label="ユーザー名（メールアドレス）"
                        type="text"
                        color="primary"
                      />
                      <v-text-field
                        v-model="password"
                        prepend-icon="mdi-lock"
                        name="password"
                        label="パスワード"
                        type="password"
                        color="primary"
                      />
                    <!-- <p class="ma-0 text-right">
                      <a href="">パスワードをお忘れの方はこちら</a>
                    </p> -->
                    </v-card-text>
                    <v-card-actions>
                      <v-row class="text-center">
                        <v-col cols="12" class="pt-0">
                          <v-btn
                            color="primary"
                            :outlined="true"
                            :class="'outset'"
                            :style="{ 'border-radius': '20px' }"
                            :disabled="isDisabledSignIn"
                            type="submit"
                            width="280"
                            @click="signIn"
                          >
                            ログイン
                          </v-btn>
                        </v-col>
                        <v-col
                          cols="12"
                          class="black--text pa-0 pt-2 text-sm-body-2"
                        >
                          または
                        </v-col>
                        <v-col cols="12">
                          <v-btn
                            color="primary"
                            :outlined="true"
                            :class="'outset border-gradient'"
                            :style="{ 'border-radius': '20px' }"
                            :loading="loading"
                            width="280"
                            @click="signInWithGoogle"
                          >
                            <v-icon
                              small
                              class="mr-2"
                              style="text-transform: none"
                            >
                              mdi-google
                            </v-icon>
                            Googleアカウントでログイン
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-form>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    email: '',
    password: ''
  }),

  computed: {
    loginStyles() {
      return {
        bg: {
          'border-radius': '20px',
          'background-color': this.$vuetify.theme.themes.light.background
        }
      };
    },
    isDisabledSignIn() {
      return !(this.email.length > 0 && this.password.length > 0);
    },
    isSigningInProcess() {
      return this.$store.getters['authentications/getSigningInProcess'];
    }
  },

  methods: {
    signIn() {
      const payload = {
        email: this.email,
        password: this.password
      };
      this.$store.dispatch('authentications/signIn', payload);
    },
    signInWithGoogle() {
      this.$store.dispatch('authentications/signInWithGoogle');
    }
  },

  async mounted() {
    this.$store.dispatch('authentications/signInCallback');
  }
};
</script>

<style scoped></style>
